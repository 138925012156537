export default
{
  time_zone: 'America/Costa_Rica',
  locale: 'es-CR',
  currency: 'CRC',
  production: ('false' == 'true'),
  // Orígenes remotos para recibir mensajes vía postMessage
  remote_origins: JSON.parse('["https://suite.motelnowapp.com","https://suite.motelnow.com.co","https://suite.motelnow.cr"]'),
  // Listado de regiones o geografías soportadas
  regions: JSON.parse('[{"region_id":1,"region_name":"Bogotá","region_latitude":4.6486259,"region_longitude":-74.2478946},{"region_id":2,"region_name":"Medellín","region_latitude":6.268844,"region_longitude":-75.6664328},{"region_id":3,"region_name":"Cali","region_latitude":3.3952332,"region_longitude":-76.5957048},{"region_id":5,"region_name":"Cúcuta","region_latitude":7.908843,"region_longitude":-72.5456205}]'),
}
